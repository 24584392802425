<template>
	<div class="pui-form">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<imofalformsform-header v-model="model"></imofalformsform-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#driverlistmenu'">{{ $t('driverlistmenu.maintab') }}</v-tab>
				<v-tab v-if="!isCreatingElement" :key="1" :href="'#driverlisttab'">{{ $t('driverlist.maintab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'driverlistmenu'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										v-if="!this.isCreatingElement"
										:id="`driverlistmenu-portcall`"
										attach="driverlistmenu-portcall"
										:label="$t('driverlistmenu.portcall')"
										disabled
										toplabel
										clearable
										v-model="model"
										modelName="vlupportcalls"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
									<pui-select
										v-if="this.isCreatingElement"
										:id="`driverlistmenu-portcall`"
										attach="driverlistmenu-portcall"
										:label="$t('driverlistmenu.portcall')"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid' || this.$route.params.parentpk"
										toplabel
										clearable
										v-model="model"
										:modelName="isCreatingElement ? 'vlupportcalls' : 'vlupportcalls'"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="padding-top: 20px; margin-left: 50px">
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="padding-top: 20px; margin-left: -50px">
									<v-radio-group
										v-if="this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('driverlistmenu.isarrival')"
											:checked="selectedOption === $t('driverlistmenu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('driverlistmenu.isdeparture')"
											:checked="selectedOption === $t('driverlistmenu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
									<v-radio-group
										v-if="!this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										disabled
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('driverlistmenu.isarrival')"
											:checked="selectedOption === $t('driverlistmenu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('driverlistmenu.isdeparture')"
											:checked="selectedOption === $t('driverlistmenu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.ship')">
										<v-row dense>
											<v-col>
												<pui-text-field
													:id="`driverlistmenu-shipname`"
													v-model="model.portcall.mship.shipname"
													:label="$t('driverlistmenu.shipname')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`driverlistmenu-imo`"
													v-model="model.portcall.mship.imo"
													:label="$t('driverlistmenu.imo')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`driverlistmenu-callsign`"
													v-model="model.portcall.mship.callsign"
													:label="$t('driverlistmenu.callsign')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`driverlistmenu-flag`"
													attach="driverlistmenu-flag"
													:label="$t('driverlistmenu.flag')"
													toplabel
													disabled
													clearable
													reactive
													v-model="model.portcall"
													modelName="mcountries"
													:modelFormMapping="{ countrycode: 'flag' }"
													:itemsToSelect="itemsToSelectFlag"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode}` + ` - ` + `${item.countrydesc}`"
													:order="{ countrydesc: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.voyage')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`driverlistmenu-voyagenum`"
													v-model="model.portcall.voyagenum"
													:label="$t('driverlistmenu.voyagenum')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`driverlistmenu-portofarrival`"
													attach="driverlistmenu-portofarrival"
													:label="this.labelportarrival"
													toplabel
													v-model="model.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '2'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`driverlistmenu-nextport`"
													attach="driverlistmenu-nextport"
													:label="$t('driverlistmenu.nextport')"
													toplabel
													reactive
													v-model="model.portcall"
													disabled
													modelName="mports"
													:modelFormMapping="{ countrycode: 'nextcountry', portcode: 'nextport' }"
													:itemsToSelect="itemToSelectNextPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`driverlistmenu-lastport`"
													attach="driverlistmenu-lastport"
													:label="$t('driverlistmenu.lastport')"
													toplabel
													reactive
													disabled
													v-model="model.portcall"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
													:itemsToSelect="itemToSelectPreviousPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '1' && isCreatingElement"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-date-field
													:id="`driverlistmenu-arrivaldate`"
													:label="$t('driverlistmenu.arrivaldate')"
													toplabel
													time
													v-model="model.arrivaldate"
													disabled
												></pui-date-field>
											</v-col>
											<!-- NMSWG-1586. Fixed Arrival and Departure date in form and report-->
											<v-col
												v-if="this.model.indarrivaldeparture === '1' && !isCreatingElement"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<!-- arrivaldate -->
												<pui-date-field
													:id="`driverlistmenu-arrivaldate`"
													:label="$t('driverlistmenu.arrivaldate')"
													toplabel
													time
													v-model="arrivaldateSelected"
													disabled
												></pui-date-field>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '2' && isCreatingElement"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-date-field
													:id="`driverlistmenu-departuredate`"
													:label="$t('driverlistmenu.departuredate')"
													toplabel
													time
													v-model="model.departuredate"
													disabled
												></pui-date-field>
											</v-col>
											<!-- NMSWG-1586. Fixed Arrival and Departure date in form and report-->
											<v-col
												v-if="this.model.indarrivaldeparture === '2' && !isCreatingElement"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<!-- departuredate -->
												<pui-date-field
													:id="`driverlistmenu-departuredate`"
													:label="$t('driverlistmenu.departuredate')"
													toplabel
													time
													v-model="departuredateSelected"
													disabled
												></pui-date-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="'driverlisttab'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="driverlist-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ imofalformid: 'imofalformid' }"
						:parentModel="model"
						:formDisabled="this.model.status === this.statusSubmitted || this.model.status === 'Invalid'"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<nsw-imo-form-footer-btns
					:formDisabled="(this.model.status == 'Invalid' && !isCreatingElement) || !havePermissions(['WRITE_DRIVERLIST'])"
					:saveDisabled="saving"
					:value="model"
					:saveAndUpdate="saveAndUpdate"
					:showNext="isCreatingElement"
					:showDraft="!isCreatingElement && this.model.status !== this.statusSubmitted"
					:showSubmit="!isCreatingElement && this.model.status !== this.statusSubmitted"
					:showNewVersion="false"
					modelName="driverlistmenu"
					:save="save"
					:back="back"
				></nsw-imo-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { STATUS_SUBMITTED_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import driverlistActions from './DriverListMenuActions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'driverlistmenuform',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'driverlistmenu',
			tabmodel: 'driverlistmenu',
			actions: driverlistActions.actions,
			labelportarrival: this.$t('driverlistmenu.portofarrival'),
			statusSubmitted: STATUS_SUBMITTED_DESC,
			filtrado: '',
			arrivaldateSelected: '',
			departuredateSelected: ''
		};
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-driverlistmenu-portcall', (newVal) => {
			var lupaModel = newVal.model;
			if (this.isCreatingElement) {
				if (lupaModel !== null) {
					this.fillPortCallFields2(lupaModel);
					// NMSWG-1586. Fixed Arrival and Departure date in form and report
					this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
					this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
					this.arrivaldateSelected = this.model.arrivaldate;
					this.departuredateSelected = this.model.departuredate;
				} else {
					this.clearPortCallFields(lupaModel);
				}
			} else {
				if (lupaModel !== null) {
					this.fillPortCallFields(lupaModel);
					// NMSWG-1586. Fixed Arrival and Departure date in form and report
					this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
					this.arrivaldateSelected = this.model.arrivaldate;
					this.departuredateSelected = this.model.departuredate;
				} else {
					this.clearPortCallFields(lupaModel);
				}
			}
		});
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.portcall.mship.shiptypecode }];
		},
		itemsToSelectArrivalPort() {
			return [{ countrycode: this.model.portcall.countrycode, portcode: this.model.portcall.portcode }];
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.portcall.mship.flag }];
		},
		itemToSelectPreviousPort() {
			return [{ countrycode: this.model.portcall.previouscountry, portcode: this.model.portcall.previousport }];
		},
		itemToSelectNextPort() {
			return [{ countrycode: this.model.portcall.nextcountry, portcode: this.model.portcall.nextport }];
		}
	},
	watch: {
		'model.indarrivaldeparture'() {
			if (this.model.indarrivaldeparture === '1') {
				this.labelportarrival = this.$t('driverlistmenu.portofarrival');
			} else {
				this.labelportarrival = this.$t('driverlistmenu.portofdeparture');
			}
		},
		'model.isarrival'() {
			if (this.model.isarrival === '1') {
				this.model.isdeparture = '0';
				this.model.indarrivaldeparture = '1';
				this.labelportarrival = this.$t('driverlistmenu.portofarrival');
			}
		},

		'model.isdeparture'() {
			if (this.model.isdeparture === '1') {
				this.model.isarrival = '0';
				this.model.indarrivaldeparture = '2';
				this.labelportarrival = this.$t('driverlistmenu.portofdeparture');
			}
		}
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}
		},
		fillPortCallFields2(lupaModel) {
			this.model.portcall.mship.flag = lupaModel.flag;
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.etd = lupaModel.etd;
			this.model.portcall.eta = lupaModel.eta;
			this.model.portcall.ata = lupaModel.ata;
			this.model.portcall.atd = lupaModel.atd;
			this.model.indarrivaldeparture = '1';
		},
		fillPortCallFields(lupaModel) {
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.mship.flag = lupaModel.flag;
			this.model.portcall.etd = lupaModel.etd;
			this.model.portcall.eta = lupaModel.eta;
			this.model.portcall.ata = lupaModel.ata;
			this.model.portcall.atd = lupaModel.atd;
		},
		clearPortCallFields() {
			this.model.portcall.mship.flag = null;
			this.model.portcall.mship.shipname = null;
			this.model.portcall.mship.shiptypecode = null;
			this.model.portcall.mship.imo = null;
			this.model.portcall.mship.callsign = null;
			this.model.portcall.voyagenum = null;
			this.model.portcall.countryofarrival = null;
			this.model.portcall.countryofdeparture = null;
			this.model.portcall.portofarrival = null;
			this.model.portcall.portofdeparture = null;
			this.model.arrivaldate = null;
			this.model.departuredate = null;
			this.model.portcall.countrycode = null;
			this.model.portcall.portcode = null;
			this.model.portcall.previouscountry = null;
			this.model.portcall.previousport = null;
			this.model.portcall.nextcountry = null;
			this.model.portcall.nextport = null;
			this.model.portcall.etd = null;
			this.model.portcall.eta = null;
			this.model.portcall.ata = null;
			this.model.portcall.atd = null;
		}
	},

	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-driverlistmenu-portcall');
	}
};
</script>
